<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="customer-list">
        <v-card>
          <v-card-title>契約番号検索</v-card-title>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="search"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            item-key="cont_id"
            :items="contractList"
            show-select
            single-select
            :headers="headers"
            sort-by
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.cust_abbr`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    :style="'text-decoration: underline; color:#666666' "
                    v-on="on"
                  >{{ item.cust_abbr }}</a>
                </template>
                <span>{{ item.cust_id }}</span><br>
                <span>{{ item.cust_name }}</span><br>
                <span>{{ item.cust_name_eng }}</span><br>
              </v-tooltip>
            </template>
            <template v-slot:[`item.contract_status`]="{ item }">
              <label>{{
                contractStatus(item)
              }}</label>
            </template>
            <template v-slot:[`item.cont_weight`]="{ item }">
              <label>{{ formatData(item.cont_weight) }}</label>
            </template>
            <template v-slot:[`item.cont_amount`]="{ item }">
              <label>{{ formatAmount(item.ccy_mark,item.cont_amount) }}</label>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiDotsHorizontal,
} from '@mdi/js'

export default {
  props: ['id', 'selectedContract'],
  data: () => ({

    dialog: false,
    search: '',
    selectedList: [],
    icons: {
      mdiDotsHorizontal,
    },
    headers: [
      {
        text: 'ID.',
        value: 'cont_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '契約番号',
        value: 'cont_no',
        align: 'left',
        width: '14%',
        fixed: true,
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約日',
        value: 'cont_date',
        align: 'center',
        width: '13%',
        fixed: true,
      },

      // {
      //   text: '方式',
      //   value: 'ic_code',
      //   align: 'left',
      //   width: '5%',
      //   fixed: true,
      // },
      {
        text: '目的地',
        value: 'dest_name',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '重量(KG)',
        value: 'cont_weight',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '金額',
        value: 'cont_amount',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '出荷(予定）日',
        value: 'cont_ssd',
        align: 'left',
        width: '13%',
        fixed: true,
      },
      {
        text: '状態',
        value: 'cont_stat_desc',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('contractStore', ['contractList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },
  },
  watch: {
    selectedContract(val) {
      this.selectedList = val
    },
    dialog() {
      if (this.id) {
        const item = this.contractList.find(cl => cl.cust_id === this.id)
        this.selectedList.push(item)
      }
    },
  },
  methods: {
    ...mapActions('contractStore', ['loadContracts']),
    ...mapMutations('app', ['setOverlayStatus']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadContracts(this.$route.query).finally(() => {
        console.log('contractList', this.contractList)
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },

    openForm() {
      this.loadData()
    },

    goBack() {
      this.dialog = false
      console.log('selectedContract:', this.selectedList)
      this.$emit('add_contract', this.selectedList)
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
.cell-content {
  flex: 1;
  word-break: break-all;
  min-width: 0;
}
</style>
