<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="py-9">
          <span> コンテナー(新規) </span>
        </v-card-title>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div v-for="msg in apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>

        <v-form class="ma-6 mb-0 mt-3">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >契約番号</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="Form.cont_no"
                :error-messages="contIdErrors"
                outlined
                dense
                placeholder="契約番号"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <Contracts
                :selected-contract="selectedContract"
                @add_contract="setSelectedContract"
              ></Contracts>
            </v-col>
          </v-row>

          <v-row v-if="Form.cont_id">
            <v-col
              cols="12"
              md="2"
            >
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              {{ selectedContract[0].cont_id }}: {{ selectedContract[0].cust_name }}
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ selectedContract[0].cust_name_eng }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >Container No.</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="Form.cntr_no"
                outlined
                dense
                placeholder="Container No."
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >Seal No.</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="Form.seal_no"
                outlined
                dense
                placeholder="Seal No."
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="contract_name">B/L No.</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                id="contract_name"
                v-model="Form.cntr_bl"
                outlined
                dense
                placeholder="B/L No."
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cntr_memo">備考</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                id="cntr_memo"
                v-model="Form.cntr_memo"
                outlined
                dense
                placeholder="備考"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row> -->

          <div style="padding: 12px; margin: 12px 0px; border: 1px solid #ccc">
            <v-row>
              <v-col
                cols="12"
                style="text-align: right"
              >
                <v-btn color="orange" class="mt-1" outlined @click="addPellet()">
                  Add Pellet
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <v-data-table
                  ref="ArrivalActuals"
                  class="class-on-data-table elevation-1"
                  :headers="headers"
                  :items="Form.details"
                  fixed-header
                  :items-per-page="-1"
                >
                  <template v-slot:[`item.pellet_id`]="{ item }">
                    <v-autocomplete
                      v-model="item.pellet_id"
                      placeholder="ペレットID"
                      :items="pelletIds"
                      :error-messages="pelletIdErrors(item)"

                      hide-details="auto"
                      outlined
                      dense
                      @change="pelletIdChange(item)"
                    ></v-autocomplete>
                  </template>
                  <template v-slot:[`item.product_name`]="{ item }">
                    <v-text-field
                      v-model="item.product_name"
                      :error-messages="productNameErrors(item)"
                      placeholder="商品名"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.cntr_dtl_weight`]="{ item }">
                    <NumberInput
                      v-model="item.cntr_dtl_weight"
                      :error-messages="cntrDtlWeightErrors(item)"
                      :outlined="true"
                      hide-details="auto"
                    ></NumberInput>
                  </template>
                  <template v-slot:[`item.cntr_dtl_price`]="{ item }">
                    <PriceInputFixedComponent
                      v-model="item.cntr_dtl_price"
                      :fixed-num="2"
                      :error-messages="cntrDtlPriceErrors(item)"
                      :prefix="Form.ccy_mark"
                      hide-details="auto"
                      :outlined="true"
                    >
                    </PriceInputFixedComponent>
                  </template>
                  <template v-slot:[`item.cntr_dtl_amount`]="{ item }">
                    <PriceInputFixedComponent
                      v-model="item.cntr_dtl_amount"
                      :fixed-num="2"
                      :error-messages="cntrDtlAmountErrors(item)"
                      :prefix="Form.ccy_mark"
                      hide-details="auto"
                      :outlined="true"
                    ></PriceInputFixedComponent>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      class="mr-2"
                      color="red"
                      @click="deletePellet(item)"
                    >
                      {{ icons.mdiMinusCircle }}
                    </v-icon>
                  </template>
                  <template v-slot:[`body.append`]>
                    <tr>
                      <td
                        class="font-weight-bold"
                        style="background: #fafafa"
                        :colspan="3"
                      >
                        <label>合計</label>
                      </td>
                      <td
                        class="font-weight-bold"
                        style="text-align: right; background: #fafafa"
                      >
                        <label> {{ weightTotal }}</label>
                      </td>
                      <td
                        style="background: #fafafa"
                        :colspan="1"
                      ></td>
                      <td
                        class="font-weight-bold"
                        style="text-align: right; background: #fafafa"
                      >
                        <label>{{ formatAmount(amountTotal) }}</label>
                      </td>
                      <td
                        style="background: #fafafa"
                        :colspan="4"
                      ></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row v-if="detailErrors" align="center">
              <v-col
                cols="12"
                md="12"
              >
                <span style="color: red">商品を確認してください！</span>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <v-card-actions
          align="center"
          class="d-flex justify-center"
        >
          <v-btn
            color="primary"
            :loading="submitStatus"
            @click="submit(Form)"
          >
            Submit
          </v-btn>
          <v-btn
            outlined
            @click="cancelClick"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencil,
  mdiPageNextOutline,
  mdiMinusCircle,
} from '@mdi/js'

import {
  mapActions, mapMutations, mapState,
} from 'vuex'

import {
  required, minValue,
} from 'vuelidate/lib/validators'
import Contracts from '@/components/Contracts.vue'
import NumberInput from '@/components/Input/NumberInput.vue'
import PriceInputFixedComponent from '@/components/PriceInputFixedComponent.vue'

export default {
  components: {
    NumberInput,
    Contracts,
    PriceInputFixedComponent,
  },
  data: () => ({
    id: '',
    icons: {
      mdiPlus,
      mdiPencil,
      mdiMinusCircle,
      mdiPageNextOutline,
    },
    selectedContract: [],
    detailErrors: false,
    headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        value: 'disp_order',
        width: '5%',
        fixed: true,
      },
      {
        text: 'Pellet',
        align: 'left',
        sortable: false,
        value: 'pellet_id',
        width: '18%',
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        sortable: false,
        value: 'product_name',
        width: '36%',
        fixed: true,
      },
      {
        text: '重量(kg)',
        value: 'cntr_dtl_weight',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '単価',
        value: 'cntr_dtl_price',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '金額',
        value: 'cntr_dtl_amount',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
        fixed: true,
      },
    ],
    Form: {
      cont_id: '',
      cntr_no: '',
      seal_no: '',
      cntr_bl: '',
      details: [

      ],
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
    weightSum: 0,
  }),
  validations: {
    Form: {
      cont_id: {
        required,
      },
      details: {
        $each: {
          required,
          pellet_id: {
            required,
          },
          product_name: {
            required,
          },
          cntr_dtl_weight: {
            required,
            minValue: minValue(1),
          },
          cntr_dtl_price: {
            required,
            minValue: minValue(0.01),
          },
          cntr_dtl_amount: {
            required,
            minValue: minValue(0.01),
          },
        },
      },
    },
  },

  computed: {
    ...mapState('pelletStore', ['pelletIds', 'pelletNameEn']),
    ...mapState('containerStore', ['selectedContainer']),

    formatAmount() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    weightTotal() {
      let totalWeight = 0
      if (this.Form.details) {
        this.Form.details.forEach(obm => {
          if (obm.cntr_dtl_weight) {
            totalWeight += Number(obm.cntr_dtl_weight)
          }
        })
      }

      return totalWeight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    amountTotal() {
      let totalAmount = 0
      if (this.Form.details) {
        this.Form.details.forEach(obm => {
          if (obm.cntr_dtl_amount) {
            totalAmount += Number(obm.cntr_dtl_amount)
          }
        })
      }

      return totalAmount

      // return totalAmount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    contIdErrors() {
      const errors = []
      if (!this.$v.Form.cont_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_id.required && errors.push('必須項目')

      return errors
    },
    pelletIdErrors() {
      return function (item) {
        const errors = []
        if (item && item.disp_order) {
          const index = item.disp_order - 1
          if (!this.$v.Form.details.$each[index].pellet_id.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.details.$each[index].pellet_id.required && errors.push('必須項目')
        }

        return errors
      }
    },
    productNameErrors() {
      return function (item) {
        const errors = []
        if (item && item.disp_order) {
          const index = item.disp_order - 1
          if (!this.$v.Form.details.$each[index].product_name.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.details.$each[index].product_name.required && errors.push('必須項目')
        }

        return errors
      }
    },
    cntrDtlWeightErrors() {
      return function (item) {
        const errors = []
        if (item && item.disp_order) {
          const index = item.disp_order - 1
          if (!this.$v.Form.details.$each[index].cntr_dtl_weight.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.details.$each[index].cntr_dtl_weight.minValue && errors.push('0以外にしてください')
        }

        return errors
      }
    },
    cntrDtlPriceErrors() {
      return function (item) {
        const errors = []
        if (item && item.disp_order) {
          const index = item.disp_order - 1
          if (!this.$v.Form.details.$each[index].cntr_dtl_price.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.details.$each[index].cntr_dtl_price.minValue && errors.push('0以外にしてください')
        }

        return errors
      }
    },
    cntrDtlAmountErrors() {
      return function (item) {
        const errors = []
        if (item && item.disp_order) {
          const index = item.disp_order - 1
          if (!this.$v.Form.details.$each[index].cntr_dtl_amount.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.details.$each[index].cntr_dtl_amount.minValue && errors.push('0以外にしてください')
        }

        return errors
      }
    },

  },
  watch: {
    Form: {
      handler() {
        this.apierror.messages = []
      },
      deep: true,
    },
    'Form.details': {
      handler(Val) {
        const newArray = Val.map(obj => ({
          ...obj, cntr_dtl_amount: (obj.cntr_dtl_price * obj.cntr_dtl_weight).toFixed(2),
        }))
        newArray.forEach(obj1 => {
          const matchingObj = this.Form.details.find(obj2 => obj1.disp_order === obj2.disp_order)
          if (matchingObj) {
            matchingObj.cntr_dtl_amount = obj1.cntr_dtl_amount
          }
        })
      },
      deep: true,
    },
  },

  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('pelletStore', ['loadPelletIds', 'loadPellets']),
    ...mapActions('containerStore', ['createContainer']),
    ...mapMutations('app', ['setOverlayStatus']),

    formatToFixed() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadPelletIds(),

        this.loadPellets(),

        // this.loadContainer(this.$route.params.id),
      ]).then(() => {
        // this.Form = this.$deepCopy(this.selectedContainer)
        console.log('form:', this.Form)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        console.log('Form', this.Form)
        this.setOverlayStatus(false)
      })
    },
    addPellet() {
      this.detailErrors = false
      const detailId = this.Form.details.length + 1
      this.Form.details.push({
        disp_order: detailId,
        pellet_id: '',
        product_name: '',
        cntr_dtl_weight: '',
        cntr_dtl_price: '',
        cntr_dtl_amount: '',
      })
    },
    deletePellet(item) {
      if (this.Form.details.indexOf(item) !== -1) {
        this.Form.details.splice(this.Form.details.indexOf(item), 1)
        const pelletList = []
        this.Form.details.forEach((v, i) => {
          pelletList[i] = v
          pelletList[i].disp_order = i + 1
        })
      } else {
        console.log('deletePellet-----> item does not exist')
      }
    },
    pelletIdChange(item) {
      const index = this.Form.details.indexOf(item)
      this.Form.details[index].product_name = this.pelletNameEn[item.pellet_id]
    },

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    jumpToListPage() {
      this.$router.push({
        path: '/container-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    onlyOnePelletId() {
      this.apierror.messages = []
      const pelletIds = this.Form.details.map(v => v.pellet_id)
      const hasDuplicate = new Set(pelletIds).size !== pelletIds.length

      if (hasDuplicate) {
        this.apierror.messages = ['ペレットIDが重複です。']
        this.apierror.status = 'error'
      }
    },
    setSelectedContract(selectContract) {
      this.selectedContract = selectContract
      if (selectContract.length === 1) {
        console.log('newContract', selectContract)
        this.Form.cont_id = selectContract[0].cont_id
        this.Form.cont_no = selectContract[0].cont_no
      } else {
        this.Form.cont_id = ''
        this.Form.cont_no = ''
      }
    },
    setDetailErrors() {
      this.detailErrors = true
      if (this.Form.details.length > 0) {
        this.detailErrors = false
      }
    },

    submit(Form) {
      this.setDetailErrors()
      this.submitStatus = true
      this.$v.$touch()
      this.onlyOnePelletId()
      if (this.apierror.messages.length === 0 && !this.$v.$invalid && !this.detailErrors) {
        this.createContainer(Form)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.status = 'error'
        if (this.apierror.messages.length === 0) {
          this.apierror.messages.push('赤い部分の情報を修正してください')
        }
        this.submitStatus = false
      }
    },
  },

}
</script>

<style lang="scss">
.add-products-form {
  .single-product-form {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
.custom-textarea {
  // max-height: 50px; /* 指定最大高度，根据需求进行调整 */
}
</style>
